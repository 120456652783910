import findLastIndex from 'lodash.findlastindex';

import type { TransactionType } from '@SHARED/core/entities/Property';
import type { PropertyFilters } from '@SHARED/core/entities/Property/filters';
import type { IconComponent } from '@SHARED/presenters/PropertyPresenter';

import { slugify, removeDuplicates } from '@SHARED/utils';
import { LabelPresenter } from '@SHARED/presenters/LabelPresenter';

import SuitesIcon from '~icons/pilar/suites';
import BathroomsIcon from '~icons/pilar/bathrooms';
import BedroomsIcon from '~icons/pilar/bedrooms';
import ParkingSpotsIcon from '~icons/pilar/parking-spots';

export type TransactionTypeSlug = 'compra' | 'aluguel' | 'compra-ou-aluguel';

const propertyTypesBySlug = {
	'casa-de-condominio': 'Casa de condomínio',
	'casa-de-vila': 'Casa de vila',
	'terreno-em-condominio': 'Terreno em condomínio',
	apartamento: 'Apartamento',
	casa: 'Casa',
	cobertura: 'Cobertura',
	comercial: 'Comercial',
	duplex: 'Duplex',
	flat: 'Flat',
	garden: 'Garden',
	loft: 'Loft',
	studio: 'Studio',
	terreno: 'Terreno',
	triplex: 'Triplex'
} as const;

type PropertyTypesBySlugKey = keyof typeof propertyTypesBySlug;
type PropertyTypeSlug =
	| PropertyTypesBySlugKey
	| 'casas-apartamentos-coberturas-e-mais';

type PropertyTypeSlugsByValue = LowercaseKeys<
	SwapKeysAndValues<typeof propertyTypesBySlug>
>;

const propertyTypeSlugsByValue: PropertyTypeSlugsByValue = {
	'casa de condomínio': 'casa-de-condominio',
	'casa de vila': 'casa-de-vila',
	'terreno em condomínio': 'terreno-em-condominio',
	apartamento: 'apartamento',
	casa: 'casa',
	cobertura: 'cobertura',
	comercial: 'comercial',
	duplex: 'duplex',
	flat: 'flat',
	garden: 'garden',
	loft: 'loft',
	studio: 'studio',
	terreno: 'terreno',
	triplex: 'triplex'
};

const propertyTypePluralsByValue: Record<
	keyof PropertyTypeSlugsByValue,
	string
> = {
	'casa de condomínio': 'casas de condomínio',
	'casa de vila': 'casas de vila',
	'terreno em condomínio': 'terrenos em condomínio',
	apartamento: 'apartamentos',
	casa: 'casas',
	cobertura: 'coberturas',
	comercial: 'imóveis comerciais',
	duplex: 'duplexes',
	flat: 'flats',
	garden: 'gardens',
	loft: 'lofts',
	studio: 'studios',
	terreno: 'terrenos',
	triplex: 'triplexes'
};

const propertyTypeGrammaticalGenderByValue: Record<
	keyof PropertyTypeSlugsByValue,
	'feminine' | 'masculine'
> = {
	'casa de condomínio': 'feminine',
	'casa de vila': 'feminine',
	'terreno em condomínio': 'masculine',
	apartamento: 'masculine',
	casa: 'feminine',
	cobertura: 'feminine',
	comercial: 'masculine',
	duplex: 'masculine',
	flat: 'masculine',
	garden: 'masculine',
	loft: 'masculine',
	studio: 'masculine',
	terreno: 'masculine',
	triplex: 'masculine'
};

export type FilterPropertyType = keyof typeof propertyTypeSlugsByValue;

type RoomFilterOptionName =
	| 'bedrooms'
	| 'suites'
	| 'bathrooms'
	| 'parkingSpots';

export type RoomFilterObject = {
	label: string;
	value: RoomFilterOptionName;
	icon: IconComponent;
};

export const roomsFilters: RoomFilterObject[] = [
	{
		label: 'Dormitórios',
		value: 'bedrooms',
		icon: BedroomsIcon
	},
	{
		label: 'Suítes',
		value: 'suites',
		icon: SuitesIcon
	},
	{
		label: 'Banheiros',
		value: 'bathrooms',
		icon: BathroomsIcon
	},
	{
		label: 'Vagas',
		value: 'parkingSpots',
		icon: ParkingSpotsIcon
	}
];

const rangeLabelsDictionary = {
	price: {
		default: 'Valores',
		prefix: 'R$',
		suffix: '',
		mask: {
			mask: '###.###.###.###.###',
			reversed: true
		}
	},
	area: {
		default: 'Metragem',
		prefix: '',
		suffix: 'm²',
		mask: {
			mask: '###.###.###.###.###',
			reversed: true
		}
	}
};

const roomsKeys: RoomFilterOptionName[] = [
	'bedrooms',
	'bathrooms',
	'suites',
	'parkingSpots'
];

const roomsLabels: Record<string, string> = {
	bedrooms: 'Dormitórios',
	bathrooms: 'Banheiros',
	suites: 'Suítes',
	parkingSpots: 'Vagas'
};

export type PossiblePropertyTypeSlugValue =
	| PropertyTypeSlug
	| `${PropertyTypeSlug}_${PropertyTypeSlug}`;

const pathFilters = [
	'transactionType',
	'propertyTypes',
	'countries',
	'states',
	'cities',
	'regions'
] as const;

const transactionTypeSlugsByValue: Record<
	TransactionType,
	TransactionTypeSlug
> = {
	sell: 'compra',
	rent: 'aluguel',
	rentOrSell: 'compra-ou-aluguel'
} as const;

export type PropertiesListPagesRouteParams = {
	transactionTypeSlug: TransactionTypeSlug;
	propertyTypeSlug: PossiblePropertyTypeSlugValue;
	countrySlug: string;
	stateSlug: string;
	citySlug: string;
	regionSlug: string;
};

type GetPropertiesCounterTextParams = {
	propertiesCount: number | null;
	propertyTypes: string[];
};

type GetAppliedFiltersTextParams = {
	transactionType: TransactionType;
	filteredRegions: string[];
};

type PathFilterKey = (typeof pathFilters)[number];

export type PathFiltersObject = Pick<PropertyFilters, PathFilterKey>;

type GetFiltersFromRouteParamsExtraParams = {
	citiesBySlug: Record<string, string>;
	regionsBySlug: Record<string, string>;
	legacyQueryFilters?: Partial<PathFiltersObject>;
};

export class PropertiesFiltersPresenter {
	static isPathFilter(filter: string): filter is PathFilterKey {
		return pathFilters.includes(filter as any);
	}

	static getPathFromFilter({
		transactionType,
		propertyTypes,
		countries,
		states,
		cities,
		regions
	}: Partial<PropertyFilters>): string {
		const self = PropertiesFiltersPresenter;

		const orderedFilters = [
			{
				getParsedValue: () => self.getSlugFromTransactionType(transactionType),
				hasValue: true
			},
			{
				getParsedValue: () => self.getSlugFromPropertyTypes(propertyTypes),
				hasValue: !!(propertyTypes && propertyTypes.length > 0)
			},
			{
				getParsedValue: () => self.getSlugFromCountries(countries),
				hasValue: !!(countries && countries.length > 0)
			},
			{
				getParsedValue: () => self.getSlugFromStates(states),
				hasValue: !!(states && states.length > 0)
			},
			{
				getParsedValue: () => self.getSlugFromCities(cities),
				hasValue: !!(cities && cities.length > 0)
			},
			{
				getParsedValue: () => self.getSlugFromRegions(regions),
				hasValue: !!(regions && regions.length > 0)
			}
		];

		const lastDefinedIndex = findLastIndex(
			orderedFilters,
			filter => filter.hasValue
		);

		const filtersPath = orderedFilters
			.slice(0, lastDefinedIndex + 1)
			.map(filter => filter.getParsedValue())
			.filter(Boolean)
			.join('/');

		return `/imoveis/${filtersPath}`;
	}

	static getFilterParamsFromPath(
		path: string
	): Partial<PropertiesListPagesRouteParams> {
		const pathPrefix = '/imoveis/';

		if (!path.startsWith(pathPrefix)) return {};

		const [
			transactionTypeSlug,
			propertyTypeSlug,
			countrySlug,
			stateSlug,
			citySlug,
			regionSlug
		] = path.replace(pathPrefix, '').trim().split('/') as [
			TransactionTypeSlug?,
			PossiblePropertyTypeSlugValue?,
			string?,
			string?,
			string?,
			string?
		];

		return {
			transactionTypeSlug,
			propertyTypeSlug,
			countrySlug,
			stateSlug,
			citySlug,
			regionSlug
		};
	}

	static #mergeSlugWithLegacyFilters(
		slug: string = '',
		legacyFilters: string[] = []
	): string {
		const slugifiedLegacyFilters = legacyFilters.map(slugify);

		if (!slug) return removeDuplicates(legacyFilters).join('_');

		const slugItems = slug.split('_');

		const mergedItems = slugItems.concat(slugifiedLegacyFilters);

		return removeDuplicates(mergedItems).join('_');
	}

	static #mergeTransactionSlugTypeWithLegacyFilters(
		transactionTypeSlug: TransactionTypeSlug | null | undefined = null,
		{ transactionType: legacyTransactionType }: Partial<PathFiltersObject> = {}
	): TransactionType {
		if (transactionTypeSlug) {
			return PropertiesFiltersPresenter.getTransactionTypeFromSlug(
				transactionTypeSlug
			);
		}

		if (!legacyTransactionType) return 'rentOrSell';

		return legacyTransactionType in transactionTypeSlugsByValue
			? legacyTransactionType
			: 'rentOrSell';
	}

	static getFiltersFromRouteParams(
		{ transactionTypeSlug, ...params }: Partial<PropertiesListPagesRouteParams>,
		{
			citiesBySlug,
			regionsBySlug,
			legacyQueryFilters = {}
		}: GetFiltersFromRouteParamsExtraParams
	): PathFiltersObject {
		const transactionType =
			PropertiesFiltersPresenter.#mergeTransactionSlugTypeWithLegacyFilters(
				transactionTypeSlug,
				legacyQueryFilters
			);

		const propertyTypeSlug =
			PropertiesFiltersPresenter.#mergeSlugWithLegacyFilters(
				params.propertyTypeSlug,
				legacyQueryFilters.propertyTypes
			);

		const citySlug = PropertiesFiltersPresenter.#mergeSlugWithLegacyFilters(
			params.citySlug,
			legacyQueryFilters.cities
		);

		const countrySlug = PropertiesFiltersPresenter.#mergeSlugWithLegacyFilters(
			params.countrySlug,
			legacyQueryFilters.countries
		);

		const stateSlug = PropertiesFiltersPresenter.#mergeSlugWithLegacyFilters(
			params.stateSlug,
			legacyQueryFilters.states
		);

		const regionSlug = PropertiesFiltersPresenter.#mergeSlugWithLegacyFilters(
			params.regionSlug,
			legacyQueryFilters.regions
		);

		const propertyTypes =
			PropertiesFiltersPresenter.getPropertyTypesFromSlug(propertyTypeSlug);

		const cities = PropertiesFiltersPresenter.getCitiesFromSlug(
			citySlug,
			citiesBySlug
		);

		const regions = PropertiesFiltersPresenter.getRegionsFromSlug(
			regionSlug,
			regionsBySlug
		);

		return {
			transactionType,
			propertyTypes,
			countries: PropertiesFiltersPresenter.getCountriesFromSlug(countrySlug),
			states: PropertiesFiltersPresenter.getStatesFromSlug(stateSlug),
			cities,
			regions
		};
	}

	static #transactionTypesBySlug: Record<TransactionTypeSlug, TransactionType> =
		{
			compra: 'sell',
			aluguel: 'rent',
			'compra-ou-aluguel': 'rentOrSell'
		} as const;

	static getTransactionTypeFromSlug(
		slug: TransactionTypeSlug | null | undefined
	): TransactionType {
		if (!slug) return 'rentOrSell';

		return (
			PropertiesFiltersPresenter.#transactionTypesBySlug[
				slug as TransactionTypeSlug
			] || 'rentOrSell'
		);
	}

	static availablePropertyTypes = Object.values(propertyTypesBySlug);

	static getPropertyTypePlural(
		propertyType: FilterPropertyType | string
	): string | null {
		const parsedPropertyType = propertyType.trim().toLowerCase() as
			| FilterPropertyType
			| '';

		const isValidPropertyType =
			parsedPropertyType && parsedPropertyType in propertyTypePluralsByValue;

		if (!isValidPropertyType) return null;

		return propertyTypePluralsByValue[parsedPropertyType];
	}

	static getPropertyTypeGrammaticalGender(
		propertyType: FilterPropertyType | string
	): 'feminine' | 'masculine' | null {
		const parsedPropertyType = propertyType.trim().toLowerCase() as
			| FilterPropertyType
			| '';

		const isValidPropertyType =
			parsedPropertyType &&
			parsedPropertyType in propertyTypeGrammaticalGenderByValue;

		if (!isValidPropertyType) return null;

		return propertyTypeGrammaticalGenderByValue[parsedPropertyType];
	}

	static getPropertyTypesFromSlug(
		slug: PropertyTypeSlug | string | null | undefined
	): (PropertyTypeSlug | string)[] {
		if (!slug || slug === 'casas-apartamentos-coberturas-e-mais') return [];

		return (
			slug
				.split('_')
				.map(
					slug =>
						propertyTypesBySlug[slug.toLowerCase() as PropertyTypesBySlugKey]
				)
				.filter(Boolean) || []
		);
	}

	static getCountriesFromSlug(slug: string | null | undefined): string[] {
		return slug === 'brasil'
			? []
			: PropertiesFiltersPresenter.#getStringArrayValueFromSlug(slug);
	}

	static getStatesFromSlug(slug: string | null | undefined): string[] {
		return slug === 'estados'
			? []
			: PropertiesFiltersPresenter.#getStringArrayValueFromSlug(slug);
	}

	static getCitiesFromSlug(
		slug: string | null | undefined,
		citiesBySlug: Record<string, string>
	): string[] {
		if (slug === 'cidades') return [];

		return PropertiesFiltersPresenter.#getStringArrayValueFromSlug(slug)

			.map(slug => citiesBySlug[slug])
			.filter(Boolean);
	}

	static getRegionsFromSlug(
		slug: string | null | undefined,
		regionsBySlug: Record<string, string>
	): string[] {
		if (slug === 'todos-os-bairros') return [];

		return PropertiesFiltersPresenter.#getStringArrayValueFromSlug(slug)
			.map(slug => regionsBySlug[slug])
			.filter(Boolean);
	}

	static #getStringArrayValueFromSlug(
		slug: string | null | undefined
	): string[] {
		return slug?.split('_').map(slugify).filter(Boolean) || [];
	}

	static getSlugFromSinglePropertyType(
		propertyType: FilterPropertyType | string | null | undefined
	): PropertyTypeSlug | null {
		if (!propertyType) return null;

		return (
			propertyTypeSlugsByValue[
				propertyType.toLowerCase() as FilterPropertyType
			] || null
		);
	}

	static getSlugFromPropertyTypes(
		propertyTypes: (FilterPropertyType | string)[] | null | undefined = []
	): PossiblePropertyTypeSlugValue {
		const defaultValue = 'casas-apartamentos-coberturas-e-mais';

		if (!propertyTypes?.length) return defaultValue;

		const slugs = propertyTypes
			.map(PropertiesFiltersPresenter.getSlugFromSinglePropertyType)
			.filter(Boolean)
			.join('_') as PossiblePropertyTypeSlugValue | '';

		return slugs || defaultValue;
	}

	static getSlugFromTransactionType(
		transactionType: TransactionType | null | undefined
	): TransactionTypeSlug {
		return (
			transactionTypeSlugsByValue[transactionType || 'rentOrSell'] ||
			'compra-ou-aluguel'
		);
	}

	static getSlugFromCountries(
		values: string[] | null | undefined
	): string | null {
		if (!values?.length) return 'brasil';

		return PropertiesFiltersPresenter.#getSlugFromStringArrayValue(values);
	}

	static getSlugFromStates(values: string[] | null | undefined): string | null {
		if (!values?.length) return 'estados';

		return PropertiesFiltersPresenter.#getSlugFromStringArrayValue(values);
	}

	static getSlugFromCities(values: string[] | null | undefined): string | null {
		if (!values?.length) return 'cidades';

		return PropertiesFiltersPresenter.#getSlugFromStringArrayValue(values);
	}

	static getSlugFromRegions(
		values: string[] | null | undefined
	): string | null {
		if (!values?.length) return 'todos-os-bairros';

		return PropertiesFiltersPresenter.#getSlugFromStringArrayValue(values);
	}

	static #getSlugFromStringArrayValue(
		values: string[] | null | undefined
	): string | null {
		return values?.map(slugify).filter(Boolean).join('_') || null;
	}

	static #transactionTypeTextByValue: Record<TransactionType, string> = {
		sell: 'Compra',
		rent: 'Aluguel',
		rentOrSell: 'Compra ou aluguel'
	};

	static getTransactionTypeTextValue(transactionType: TransactionType) {
		return PropertiesFiltersPresenter.#transactionTypeTextByValue[
			transactionType
		];
	}

	static getPropertiesCounterText({
		propertiesCount,
		propertyTypes
	}: GetPropertiesCounterTextParams): string | null {
		if (!propertiesCount) return null;

		const propertyTypesLabels = propertyTypes
			.map(type =>
				propertiesCount === 1
					? type.toLocaleLowerCase()
					: PropertiesFiltersPresenter.getPropertyTypePlural(type) || ''
			)
			.filter(Boolean);

		if (!propertyTypesLabels.length) {
			const label = propertiesCount > 1 ? 'imóveis' : 'imóvel';
			return `${propertiesCount} ${label}`;
		}

		if (propertyTypesLabels.length === 1) {
			return `${propertiesCount} ${propertyTypesLabels[0]}`;
		}

		const firstLabels = propertyTypesLabels.slice(0, -1);
		const lastLabel = propertyTypesLabels[propertyTypesLabels.length - 1];

		const firstLabelsText = firstLabels.join(', ');

		return propertiesCount === 1
			? `${propertiesCount} ${firstLabelsText} ou ${lastLabel}`
			: `${propertiesCount} ${firstLabelsText} e ${lastLabel}`;
	}

	static appliedTransactionTypeTextByValue: Record<TransactionType, string> = {
		rent: 'alugar',
		sell: 'comprar',
		rentOrSell: 'comprar ou alugar'
	};

	static getAppliedFiltersText({
		transactionType,
		filteredRegions
	}: GetAppliedFiltersTextParams): string | null {
		const regions = filteredRegions
			.map(region => region.trim())
			.filter(Boolean);

		if (!regions.length) return null;

		const transactionTypeText =
			PropertiesFiltersPresenter.appliedTransactionTypeTextByValue[
				transactionType
			];

		if (regions.length === 1) {
			return `para ${transactionTypeText} em ${regions[0]}`;
		}

		const firstRegions = regions.slice(0, -1);
		const lastRegion = regions[regions.length - 1];

		const firstRegionsText = firstRegions.join(', ');
		const regionsText = `${firstRegionsText} e ${lastRegion}`;

		return `para ${transactionTypeText} em ${regionsText}`;
	}

	static getRangeLabel({
		values: [min, max],
		initialValues: [minInitialValue, maxInitialValue],
		label
	}: {
		values: [number, number];
		initialValues: [number, number];
		label: 'price' | 'area';
	}): string {
		const {
			prefix = '',
			suffix = '',
			default: defaultLabel,
			mask
		} = rangeLabelsDictionary[label];

		const hasMaxValue = max !== maxInitialValue && !!max;
		const hasMinValue = min !== minInitialValue && !!min;

		if (!hasMaxValue && !hasMinValue) return defaultLabel;

		const minvalue = LabelPresenter.maskValue(min, mask);
		const maxValue = LabelPresenter.maskValue(max, mask);

		const minLabel = `${prefix}${minvalue}${suffix}`;
		const maxLabel = `${prefix}${maxValue}${suffix}`;

		if (hasMaxValue && hasMinValue) return `${minLabel} até ${maxLabel}`;
		if (hasMaxValue && !hasMinValue) return `${maxLabel} -`;
		return `${minLabel} +`;
	}

	static getRoomsLabel(
		filters: PropertyFilters,
		initialValues: PropertyFilters
	): string {
		const allFiltersMatchInitialValues = roomsKeys.every(
			key => filters[key] === initialValues[key]
		);

		if (allFiltersMatchInitialValues) return 'Ambientes';

		return roomsKeys
			.reduce<string[]>((acc, key) => {
				const value = filters[key];

				if (value === 0) return acc;

				const label = roomsLabels[key];

				return [...acc, `${value}+ ${label}`];
			}, [])
			.join(', ');
	}

	static getPropertyTypesLabel({ propertyTypes }: PropertyFilters): string {
		if (!propertyTypes.length) return 'Tipo de imóvel';

		return propertyTypes.join(', ');
	}

	static getLocationLabel({ regions, cities }: PropertyFilters): string {
		if (!regions.length && !cities.length) return 'Localização';

		return [...regions, ...cities].join(', ');
	}
}
